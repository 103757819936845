/* eslint-disable max-len */
import { Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirmationModalContext } from '../../../hooks/useConfirmationModalContext';
import { CertifyIcon, ClearIcon, ViewErrorsIcon } from '../../../icons/Icons';
import { useAppDispatch } from '../../../redux/hooks';
import { setDQDFilter } from '../../../redux/slices/dataQualitySlice';
import { createNotification } from '../../../redux/slices/notificationSlice';
import { fetchCertificationDataCollectionDetails } from '../../../redux/thunks/certificationThunks';
import {
  getCurrentUserAccess,
  isValidCode,
} from '../../../services/base.service';
import {
  createCertification,
  uncertifyCertification,
} from '../../../services/certification.service';
import { appRoutePaths } from '../../../services/route.service';
import { ApiResponseModel } from '../../../types/ApiResponseModel';
import {
  CertifiableWithReason,
  CertificationSchoolItem,
  CertificationStatus,
} from '../../../types/CertificationDetailModel';
import { CertificationCreationModel } from '../../../types/CertificationViewModel';
import { PillType } from '../../../types/PillType';
import { SchoolCertificationGridPropTypes } from '../../../types/propTypes/SchoolCertificationGridPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import Pill from '../../Pill/Pill';
import StyledGrid from '../../StyledGrid/StyledGrid';
import './SchoolCertificationGrid.css';

const SchoolCertificationGrid = ({
  items,
  schoolYear,
  dataCollection,
  isDistrictCertified,
}: SchoolCertificationGridPropTypes): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirmContext = useConfirmationModalContext();
  const isCertifiable =
    dataCollection.isCertifiableWithReason == CertifiableWithReason.YES;

  const handleRowId = (row: CertificationSchoolItem): string => {
    return row.schoolNumber;
  };

  const handleDateFormat = (value: string): string => {
    /* istanbul ignore next */
    if (!value) {
      return value;
    }
    return formatToDateString(value);
  };

  const handleSchoolCertify = async (
    model: CertificationCreationModel,
    schoolDisplay: string
  ): Promise<void> => {
    confirmContext.setOptions({
      title: 'Are you sure?',
      content: (
        <div className="confirm-container">
          Are you sure you want to certify&nbsp;
          {schoolDisplay}?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await createCertification(model);
        if (isValidCode(response.status)) {
          dispatch(
            fetchCertificationDataCollectionDetails({
              schoolYear,
              collectionId: dataCollection.id,
            })
          );
          dispatch(
            createNotification({
              severity: 'success',
              children: 'School certification successful',
            })
          );
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleUncertify = async (
    id: string,
    schoolDisplay: string
  ): Promise<void> => {
    confirmContext.setOptions({
      title: 'Are you sure?',
      content: (
        <div className="confirm-container">
          Are you sure you want to un-certify&nbsp;
          {schoolDisplay}?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await uncertifyCertification(id);
        if (isValidCode(response.status)) {
          dispatch(
            fetchCertificationDataCollectionDetails({
              schoolYear,
              collectionId: dataCollection.id,
            })
          );
          dispatch(
            createNotification({
              severity: 'success',
              children: 'School uncertification successful',
            })
          );
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleViewErrors = (): void => {
    dispatch(setDQDFilter(dataCollection.name));
    navigate(appRoutePaths.DataQualityDashboard);
  };

  const columns: GridColDef<CertificationSchoolItem>[] = [
    {
      field: 'schoolNumber',
      headerName: 'School Number',
      description: 'Number of school',
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'schoolName',
      headerName: 'School Name',
      description: 'Name of school',
      flex: 1,
    },
    {
      field: 'certificationDate',
      headerName: 'Certification Date',
      description: 'Date of certification',
      flex: 1,
      valueGetter: handleDateFormat,
    },
    {
      field: 'certifiedBy',
      headerName: 'Certified By',
      description: 'Who certified the record',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status of certification',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<CertificationSchoolItem>) => {
        const isCurrentlyCertified =
          params.row.status === CertificationStatus.CERTIFIED;
        const canViewErrors =
          params.row.status === CertificationStatus.ERRORS &&
          !isCurrentlyCertified;
        const pillValue =
          dataCollection.isCertifiableWithReason ==
            CertifiableWithReason.OUTSIDE_DATE_RANGE &&
          !canViewErrors &&
          !isCurrentlyCertified
            ? 'Outside of Date Range'
            : params.value;

        return (
          params.value && (
            <Pill
              status={pillValue}
              value={pillValue}
              pillType={PillType.CERTIFICATION_STATUS}
              key={`pill-status-${params.row.schoolNumber}`}
            />
          )
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      description:
        'Actions to allow you to certify, uncertify or view errors for schools',
      type: 'actions',
      renderCell: (params: GridRenderCellParams<CertificationSchoolItem>) => {
        const schoolDisplay = `${params.row.schoolName} (${params.row.schoolNumber})`;
        const isCurrentlyCertified =
          params.row.status === CertificationStatus.CERTIFIED;
        const canCertify =
          params.row.status === CertificationStatus.READY_TO_CERTIFY &&
          isCertifiable;
        const canViewErrors =
          params.row.status === CertificationStatus.ERRORS &&
          !isCurrentlyCertified;
        const canUncertify =
          isCurrentlyCertified && !isDistrictCertified && isCertifiable;

        /* istanbul ignore next */
        const getDistrictId =
          getCurrentUserAccess()?.currentDistrict?.districtId || '';

        const model: CertificationCreationModel = {
          schoolIdentifier: params.row.schoolNumber,
          leaIdentifier: getDistrictId,
          dataCollectionSchoolYear: schoolYear,
          dataCollectionId: dataCollection.id,
        };

        return [
          canCertify && (
            <Tooltip
              title="Certify"
              key={`certify-school-${params.row.schoolNumber}`}
            >
              <GridActionsCellItem
                icon={<CertifyIcon />}
                label="Certify School"
                data-testid={`certify-button-${params.row.schoolNumber}`}
                onClick={() => handleSchoolCertify(model, schoolDisplay)}
              />
            </Tooltip>
          ),
          canViewErrors && (
            <Tooltip
              title="View Errors"
              key={`view-errors-school-${params.row.schoolNumber}`}
            >
              <GridActionsCellItem
                icon={<ViewErrorsIcon />}
                label="View Errors"
                data-testid={`view-errors-button-${params.row.schoolNumber}`}
                onClick={() => handleViewErrors()}
              />
            </Tooltip>
          ),
          canUncertify && (
            <Tooltip
              title="Uncertify"
              key={`uncertify-school-${params.row.schoolNumber}`}
            >
              <GridActionsCellItem
                icon={<ClearIcon />}
                label="Uncertify"
                data-testid={`uncertify-button-${params.row.schoolNumber}`}
                onClick={() =>
                  handleUncertify(params.row.itemId, schoolDisplay)
                }
              />
            </Tooltip>
          ),
        ];
      },
    },
  ];

  return (
    <div className="school-certification-grid">
      <StyledGrid<CertificationSchoolItem>
        columns={columns}
        rows={items}
        getRowId={handleRowId}
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'schoolName', sort: 'asc' }],
        //   },
        // }}
        hideFooter
      ></StyledGrid>
    </div>
  );
};

export default SchoolCertificationGrid;
